import React from 'react';
import { Link } from 'react-router-dom';
import { SalesOrdersQuery } from '../generated/graphql';

import dateFormat from 'dateformat';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import LockIcon from '@mui/icons-material/Lock';

import { orderStatusDisplayString } from '../utils';
import { OrderCustomer } from './OrderCustomer';

export interface OrderPreviewItemProps {
  order: SalesOrdersQuery['salesOrders'][0];
}

export const OrderPreviewItem: React.FC<OrderPreviewItemProps> = ({
  order,
}) => {
  const lockingFulfilment = order.activeFulfilment;

  return (
    <ListItem button>
      <Link
        to={() => (lockingFulfilment ? `/` : `/order/${order.id}`)}
        style={{ all: 'unset', color: 'rgba(0, 0, 0, 0.87)', width: '100%' }}
      >
        <Grid container justifyContent="space-between" alignContent="center">
          <Grid
            item
            container
            direction="column"
            xs={1}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingRight: '1rem',
            }}
          >
            <Grid item>
              <Typography variant="caption">
                {lockingFulfilment
                  ? lockingFulfilment.createdBy.givenName
                  : null}
              </Typography>
            </Grid>
            <Grid item>
              {lockingFulfilment && <LockIcon color="disabled" />}
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignContent="flex-start"
            xs={5}
          >
            <Grid item>
              <OrderCustomer
                customerName={order.customerName}
                debtorName={order.customer.name}
              />
            </Grid>
            <Grid item>
              <Typography color="textSecondary">
                {dateFormat(order.dateOrdered, 'yyyy/mm/dd')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid
              item
              xs={5}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography color="textSecondary">{order.id}</Typography>
            </Grid>
            <Grid
              item
              xs={7}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}
            >
              <Typography color="textSecondary">
                {orderStatusDisplayString(order)}
              </Typography>
            </Grid>
            {order.notes && (
              <Grid item xs={12}>
                <Typography
                  color="textSecondary"
                  align="right"
                  style={{ marginTop: '0.5em', fontWeight: 500 }}
                >
                  {order.notes}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Link>
    </ListItem>
  );
};
