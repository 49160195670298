import React, { useState, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import { match as Match, useHistory, Redirect } from 'react-router-dom';

import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import BackIcon from '@mui/icons-material/ArrowBack';
import IncrementIcon from '@mui/icons-material/Add';
import DecrementIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { OrderLineTable } from './OrderLineTable';

import { useOrderQuery } from '../graphql/queries/useOrderQuery';
import { useCreateFulfilmentMutation } from '../graphql/mutations/useCreateFulfilmentMutation';
import { Loading } from './Loading';
import { Error } from './Error';
import { OrderSummary } from './OrderSummary';
import { AdapterLink } from './AdapterLink';

import { ConfirmationDialog } from './ConfirmationDialog';
import { ChangeQuantityButton } from './ChangeQuantityButton';

import { useCompleteFulfilmentMutation } from '../graphql/mutations/useCompleteFulfilmentMutation';
import { useAbandonFulfilmentMutation } from '../graphql/mutations/useAbandonFulfilmentMutation';

import { ShipmentActions, useTotalItemCount } from '../state/shipment';
import { useCurrentUser } from '../state/user';
import {
  SalesOrderLine,
  Fulfilment,
  SalesOrderStatus,
  SalesOrder,
  SalesOrderQuery,
  useShippingProvidersQuery,
  FulfilmentLine,
} from '../generated/graphql';
import { orderStatusDisplayString } from '../utils';
import { GraphQLError } from 'graphql';

class CompletionError extends window.Error {
  constructor(message: string) {
    super(message);
    this.message = message;
  }
}

export interface OrderPageProps {
  match: Match<{ id: string }>;
}

type NonNull<T> = Exclude<T, null | undefined>;
type ActiveFulfilment = NonNull<
  NonNull<SalesOrderQuery['salesOrder']>['activeFulfilment']
>;

function isFrozen(data: SalesOrderQuery) {
  const so = data!.salesOrder!;
  const inProgress =
    so.status === SalesOrderStatus.NotProcessed ||
    so.status === SalesOrderStatus.PartiallyProcessed;
  return (
    !inProgress ||
    so.onHold ||
    orderStatusDisplayString(so as unknown as SalesOrder) === 'Unavailable'
  );
}

export const OrderPage: React.FC<OrderPageProps> = ({ match }) => {
  const { id } = match.params;

  const currentUser = useCurrentUser();
  const { loading, error, data } = useOrderQuery(id);

  const activeFulfilment = data?.salesOrder?.activeFulfilment || null;

  if (id === '') return null;

  if (loading || error || !data || !data.salesOrder) {
    if (loading) return <Loading fullscreen />;
    return <Error fullscreen />;
  }

  if (activeFulfilment && activeFulfilment.createdBy.id !== currentUser!.id) {
    return <Redirect to="/" />;
  }

  const picking = !!activeFulfilment;
  const frozen = isFrozen(data);

  return (
    <Container maxWidth="md">
      <div
        style={{
          width: '100%',
          padding: '3em 3em 1em 3em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '2rem',
        }}
      >
        <IconButton
          component={AdapterLink}
          to="/"
          style={{
            marginRight: '2em',
            visibility: picking ? 'hidden' : 'visible',
          }}
          size="large"
        >
          <BackIcon />
        </IconButton>
        <OrderSummary order={data.salesOrder as any} />
      </div>
      <OrderLineTable
        salesOrderId={data.salesOrder.id}
        picking={picking}
        lines={data.salesOrder.lines as SalesOrderLine[]}
        activeFulfilment={activeFulfilment as Fulfilment}
        frozen={frozen}
      />
      <FulfilmentControls
        activeFulfilment={activeFulfilment}
        existingShippingProviderId={
          data.salesOrder.shippingProvider?.id || null
        }
        salesOrderId={id}
        frozen={frozen}
      />
    </Container>
  );
};

function useFulfilmentControls(
  salesOrderId: string,
  existingShippingProviderId: string | null,
  activeFulfilment: ActiveFulfilment | null,
) {
  const [shippingProviderId, setShippingProviderId] = useState(
    existingShippingProviderId === null ? '' : existingShippingProviderId,
  );

  const expectedShipmentCount = useTotalItemCount(
    (activeFulfilment?.lines as FulfilmentLine[]) || [],
  );

  const [overridden, setOverridden] = useState(false);
  const [shipmentItemCount_, setShipmentItemCount_] = useState<number | null>(
    null,
  );
  const setShipmentItemCount = useCallback((value: number | null) => {
    setOverridden(true);
    setShipmentItemCount_(value);
  }, []);

  const shipmentItemCount = overridden
    ? shipmentItemCount_
    : expectedShipmentCount;

  const [createFulfilment, { loading: createLoading }] =
    useCreateFulfilmentMutation(salesOrderId);

  const resetShipmentItemCountToExpected = useCallback(() => {
    if (!overridden) return;
    setShipmentItemCount_(expectedShipmentCount);
    setOverridden(false);
  }, [overridden, expectedShipmentCount]);

  const [completeFulfilment_, { loading: completeLoading }] =
    useCompleteFulfilmentMutation({
      fulfilmentId: activeFulfilment === null ? '-1' : activeFulfilment.id,
      salesOrderId,
      shippingProviderId: shippingProviderId!,
      shipmentItemCount: shipmentItemCount || 0,
    });

  const [abandonFulfilment, { loading: abandonLoading }] =
    useAbandonFulfilmentMutation(
      activeFulfilment === null ? '-1' : activeFulfilment.id,
    );

  const loading = createLoading || completeLoading || abandonLoading;

  const completeFulfilment = useCallback(
    (...args: Parameters<typeof completeFulfilment_>) =>
      completeFulfilment_(...args).catch((error) => {
        Sentry.captureException(error);

        const errors = error.graphQLErrors as GraphQLError[] | undefined;
        if (
          errors &&
          errors[0] &&
          errors[0].extensions?.code === 'BAD_USER_INPUT'
        ) {
          throw new CompletionError(errors[0].message);
        }

        throw new CompletionError('Failed to complete fulfilment!');
      }),
    [completeFulfilment_],
  );

  return {
    fulfilmentLoading: loading,
    shippingProviderId,
    setShippingProviderId,
    shipmentItemCount,
    setShipmentItemCount,
    resetShipmentItemCountToExpected,
    createFulfilment,
    completeFulfilment,
    abandonFulfilment,
  };
}

interface FulfilmentControlsProps {
  salesOrderId: string;
  existingShippingProviderId: string | null;
  activeFulfilment: ActiveFulfilment | null;
  frozen: boolean;
}

const FulfilmentControls: React.FC<FulfilmentControlsProps> = ({
  salesOrderId,
  existingShippingProviderId,
  activeFulfilment,
  frozen,
}) => {
  const shippingProviders = useShippingProvidersQuery();

  const {
    fulfilmentLoading,
    shippingProviderId,
    setShippingProviderId,
    shipmentItemCount,
    setShipmentItemCount,
    resetShipmentItemCountToExpected,
    createFulfilment,
    completeFulfilment,
    abandonFulfilment,
  } = useFulfilmentControls(
    salesOrderId,
    existingShippingProviderId,
    activeFulfilment,
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState<
    'discard' | 'confirm' | 'none'
  >('none');

  const picking = !!activeFulfilment;

  if (shippingProviders.error) return <Error fullscreen />;

  if (fulfilmentLoading) {
    return (
      <div
        style={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading fullscreen />
      </div>
    );
  }

  if (!frozen && !picking) {
    return (
      <div
        style={{
          position: 'fixed',
          padding: '1em',
          right: '2em',
          bottom: '2em',
        }}
      >
        <Fab
          onClick={() => {
            dispatch(ShipmentActions.reset());
            createFulfilment().then((x) => {
              if (x && x.data) {
                console.log(
                  `fulfilmentId returned from graphql: ${x.data.fulfilmentCreate}`,
                );
              } else {
                console.log('no/invalid fulfilmentID returned from graphql');
              }
            });
          }}
          color={picking ? 'secondary' : 'primary'}
          variant="extended"
          style={{
            margin: '1em',
          }}
        >
          {picking ? 'Cancel' : 'Pick Now'}
        </Fab>
      </div>
    );
  }

  if (!picking) return null;

  return (
    <div
      style={{
        position: 'fixed',
        padding: '1em',
        right: '2em',
        bottom: '2em',
      }}
    >
      <Fab
        onClick={() => {
          setDialogState('discard');
        }}
        color={'secondary'}
        variant="extended"
        style={{
          margin: '1em',
        }}
      >
        Discard
      </Fab>
      <Fab
        disabled={frozen}
        onClick={() => {
          resetShipmentItemCountToExpected();
          setDialogState('confirm');
        }}
        color={'primary'}
        variant="extended"
        style={{
          margin: '1em',
        }}
      >
        Confirm
      </Fab>
      <ConfirmationDialog
        open={dialogState === 'confirm'}
        close={() => setDialogState('none')}
        handleOk={() => {
          completeFulfilment({})
            .then(() => {
              dispatch(ShipmentActions.reset());
              history.push('/take-photo');
            })
            .catch((error: unknown) => {
              Sentry.captureException(error);
              if (error instanceof CompletionError) {
                window.alert(error.message);
              } else {
                window.alert('Something went wrong :/');
              }
            });
        }}
        dialogText={`Are you sure that you would like to confirm?`}
        confirmDisabled={
          shipmentItemCount === null ||
          shippingProviderId === '' ||
          (shippingProviderId !== '13' && shipmentItemCount === 0)
        }
      >
        {shippingProviders.loading ? (
          <Loading />
        ) : (
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                placeholder="Shipment Item Count"
                id="shipment-item-count"
                type="number"
                value={shipmentItemCount === null ? '' : shipmentItemCount}
                onChange={(e) => {
                  if (!/^[0-9]*$/.test(e.target.value)) return;
                  setShipmentItemCount(
                    e.target.value === '' ? null : parseInt(e.target.value),
                  );
                }}
                autoFocus
                variant="standard"
              />
              <ChangeQuantityButton
                value={shipmentItemCount}
                setValue={setShipmentItemCount}
                changeAmount={1}
              >
                <IncrementIcon style={{ width: '1em', height: '1em' }} />
              </ChangeQuantityButton>
              <ChangeQuantityButton
                value={shipmentItemCount}
                setValue={setShipmentItemCount}
                changeAmount={-1}
              >
                <DecrementIcon style={{ width: '1em', height: '1em' }} />
              </ChangeQuantityButton>
            </div>
            <br />
            <div>
              <TextField
                select
                label="Shipping Provider"
                value={shippingProviderId}
                style={{ width: '80%' }}
                onChange={(e) =>
                  setShippingProviderId(e.target.value as string)
                }
                variant="standard"
              >
                {shippingProviders.data?.shippingProviders.map((provider) => (
                  <MenuItem key={provider.id} value={provider.id}>
                    {provider.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        )}
      </ConfirmationDialog>
      <ConfirmationDialog
        open={dialogState === 'discard'}
        close={() => setDialogState('none')}
        handleOk={() => {
          abandonFulfilment();
          dispatch(ShipmentActions.reset());
          setDialogState('none');
          setShipmentItemCount(null);
        }}
        dialogText={`Are you sure that you would like to discard?`}
      />
    </div>
  );
};
