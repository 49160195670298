import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Provider as ReduxProvider, useDispatch } from 'react-redux';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { PersistGate } from 'redux-persist/integration/react';
import { Route, Router, useHistory } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

import setupRedux from './store';
import makeApollo from './services/apollo';
import { history } from './history';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import {
  LoginContentStyle,
  LoginPage,
  LoginStyle,
} from './components/LoginPage';
import { OrderPage } from './components/OrderPage';
import { PickPage } from './components/PickPage';
import { OrdersPage } from './components/OrdersPage';
import { FulfilmentsPage } from './components/FulfilmentsPage';
import { VersionNumber } from './components/VersionNumber';
import { FulfilmentStats } from './components/FulfilmentStats';
import { useUserIsLoggingIn, useUserLoggedIn } from './state/user';
import {
  ActiveFulfilmentDocument,
  FulfilmentStatus,
  useActiveFulfilmentQuery,
} from './generated/graphql';
import { Loading } from './components/Loading';
import { OrderScreenshotPage } from './components/OrderScreenshotPage';
import { AuthMode, ServerConfig } from './services/apollo/determine-server';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import {
  authEnabled,
  getAuthProvider,
  authenticationRequest,
} from './services/auth';
import { InteractionType } from '@azure/msal-browser';
import { StylesProvider } from '@mui/styles';

const SentryRoute = Sentry.withSentryRouting(Route);

export const AppInner: React.FC<{ authMode: AuthMode }> = ({ authMode }) => {
  const loggedIn = useUserLoggedIn();
  const loggingIn = useUserIsLoggingIn();
  const history = useHistory();

  const [isPicking, setIsPicking] = useState<string | null>(null);
  const { loading, data } = useActiveFulfilmentQuery({
    skip: !loggedIn,
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
  });

  useRememberScrollY();

  const apollo = useApolloClient();
  const salesOrderId = data?.me?.activeFulfilment?.salesOrder?.id;
  const status = data?.me?.activeFulfilment?.status;

  useEffect(() => {
    if (loading) {
      return;
    }

    const path = history.location.pathname;
    const validPath = (status: FulfilmentStatus | undefined, id?: string) => {
      if (id == null) {
        return (
          path.startsWith('/order') ||
          path === '/' ||
          path.startsWith('/fulfilments')
        );
      }

      return status === 'UNFINALIZED'
        ? path === '/take-photo'
        : path === `/order/${id}` || path.startsWith(`/pick/${id}`);
    };

    if (!validPath(status, salesOrderId)) {
      (async () => {
        const { data } = await apollo.query({
          query: ActiveFulfilmentDocument,
        });

        const activeSalesOrderId = data.me.activeFulfilment?.salesOrder?.id;
        const status = data.me.activeFulfilment?.status;

        if (!validPath(status, activeSalesOrderId)) {
          if (!activeSalesOrderId) history.push('/');
          else if (status === 'UNFINALIZED') history.push(`/take-photo`);
          else history.push(`/order/${activeSalesOrderId}`);
        }
      })();
    }

    if (salesOrderId && !isPicking) {
      // Started picking
      setIsPicking(salesOrderId);
      apollo.reFetchObservableQueries();
      return;
    }

    if (isPicking && !salesOrderId) {
      // Stopped picking
      setIsPicking(null);
      apollo.reFetchObservableQueries();
      return;
    }

    if (isPicking && salesOrderId && salesOrderId !== isPicking) {
      // Picking something else
      setIsPicking(salesOrderId);
      apollo.reFetchObservableQueries();
      return;
    }
  }, [apollo, loading, history, salesOrderId, status, isPicking]);

  if (!loggedIn) {
    if (authMode === 'pin') return <LoginPage />;
    if (loggingIn) {
      return <LoggingIn />;
    }
  }

  const Wrapper = authMode === 'jwt' ? MicrosoftAuth : React.Fragment;

  const activeFulfilment = data?.me?.activeFulfilment;
  const takingPhoto = activeFulfilment?.status === FulfilmentStatus.Unfinalized;
  return (
    <Wrapper>
      <SentryRoute exact path="/" component={OrdersPage} />
      <SentryRoute exact path="/fulfilments/:id?" component={FulfilmentsPage} />
      <SentryRoute exact path="/order/:id" component={OrderPage} />
      <SentryRoute
        exact
        path="/pick/:orderId/:lineId/:type"
        component={PickPage}
      />
      <SentryRoute exact path="/take-photo" component={OrderScreenshotPage} />

      {activeFulfilment != null && !takingPhoto && (
        <FulfilmentStats salesOrderId={activeFulfilment.salesOrder.id} />
      )}
    </Wrapper>
  );
};

const MicrosoftAuth: React.FC = ({ children }) => {
  if (!authEnabled) {
    window.alert(
      'Application misconfigured - `env-config.js` must contain valid information if Microsoft authentication is to be used.',
    );
    return <>{children}</>;
  }

  return (
    <MsalProvider instance={getAuthProvider()}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authenticationRequest}
      >
        <MicrosoftAuthInner>{children}</MicrosoftAuthInner>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

const MicrosoftAuthInner: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const loggedIn = useUserLoggedIn();

  // If this component renders, we are logged in. We feed the account info to
  // redux. This happens only when the component is mounted
  useEffect(() => {
    dispatch({ type: 'AAD_LOGIN_SUCCESS' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loggedIn) {
    return <LoggingIn />;
  }

  return <>{children}</>;
};

function LoggingIn() {
  return (
    <LoginContentStyle style={{ flexDirection: 'column', padding: '3em' }}>
      <LoginStyle>
        <span role="img" className="waver" aria-label="wave">
          🖐️
        </span>
      </LoginStyle>
    </LoginContentStyle>
  );
}

const redux = setupRedux();

export const store = redux.store;

export const App: React.FC<ServerConfig> = (props) => (
  <ApolloProvider client={makeApollo(props)}>
    <MuiThemeProvider theme={materialOverrides}>
      <StylesProvider injectFirst>
        <ReduxProvider store={store}>
          <PersistGate loading={<Loading />} persistor={redux.persistor}>
            <Router history={history as any}>
              <VersionNumber />
              <CssBaseline />
              <AppInner authMode={props.authMode} />
            </Router>
          </PersistGate>
        </ReduxProvider>
      </StylesProvider>
    </MuiThemeProvider>
  </ApolloProvider>
);

export const materialOverrides = createTheme({
  palette: {
    primary: {
      main: '#375954',
    },
    secondary: {
      main: '#d3d3d3',
    },
    error: {
      main: '#d35f5f',
    },
    warning: {
      main: '#e9822e',
    },
  },
});

function useRememberScrollY() {
  const history = useHistory();
  const key = 'scroll-home';
  const giveUp = 25 * 1000;

  useEffect(() => {
    return history.block(() => {
      if (window.location.pathname !== '/') return;
      window.localStorage.setItem(key, window.scrollY.toString());
    });
  }, [history]);

  useEffect(() => {
    return history.listen(() => {
      if (window.location.pathname !== '/') return;
      const scroll = window.localStorage.getItem(key);
      if (scroll == null) return;
      const amount = parseFloat(scroll);
      const start = new Date();
      (async () => {
        while (
          Date.now() - start.getTime() < giveUp &&
          window.location.pathname === '/' &&
          Math.abs(window.scrollY - amount) > 1
        ) {
          window.scrollTo(0, amount);
          await new Promise((res) => setTimeout(res, 30));
        }
      })();
    });
  });
}
